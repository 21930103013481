<template>
  <el-container class="virtual-agent-usage-container h-full">
    <page-header
      style="padding: 20px 30px;"
      :title="__('Virtual Agent Usage')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  name: "VirtualAgentUsageIndex",
  components: {
    PageHeader
  },

  data() {
    return {
      selectedComponent: "voice",
      selectedIndex: null,
      navItems: [
        {
          displayText: __("Voice"),
          label: "Voice",
          active: false,
          icon: "icon-voice.svg",
          permission: "voice task"
        },
        {
          displayText: __("Messaging"),
          label: "Messaging",
          active: false,
          icon: "icon-messaging.svg",
          permission: "Messaging"
        },
        {
          displayText: __("Workflow"),
          label: "Workflow",
          active: false,
          icon: "icon-workflow.svg",
          permission: "Workflow"
        },
        {
          displayText: __("ChatBot"),
          label: "ChatBot",
          active: false,
          icon: "icon-chatbot.svg"
        },
        {
          displayText: __("Biometric"),
          label: "Biometric",
          active: false,
          icon: "icon-biometrics.svg"
        },
        {
          displayText: __("Transcription"),
          label: "Transcription",
          active: false,
          permission: "TRANSCRIPTION"
        },
        {
          displayText: __("Tone Analyzer"),
          label: "ToneAnalyzer",
          active: false,
          permission: "TONE ANALYZER"
        },
        {
          displayText: __("Number Insights"),
          label: "NumberInsight",
          active: false,
          permission: "NUMBER INSIGHTS"
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },
    ...mapState("tasks", {
      taskFilter: state => state.taskFilter
    }),
    activeNavItem() {
      return _.find(this.navItems, navItem => navItem.active);
    }
  },

  methods: {
    ...mapActions("tasks", {
      setTaskFilter: "setTaskFilter"
    }),

    assignTaskFilter() {
      const taskFilter = "voice";

      this.setTaskFilter(taskFilter);
      this.handleNavItemChanged({ label: _.upperFirst(taskFilter) });
    },

    // the path parameter should be lower cased nav-item label in this case
    // should be either voice, messaging, workflow or chatbot (should not change the folder name)
    importComponent(path) {
      return () =>
        import("@/views/analyse/virtual-agent-usage/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.label.toLowerCase();
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });

      this.setTaskFilter(navItem.label.toLowerCase());
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  },

  created() {
    this.assignTaskFilter();
  }
};
</script>

<style lang="scss" scoped>
.virtual-agent-usage-container {
  display: flex;
  flex-direction: column;
}
</style>
